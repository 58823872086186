* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

.policyLink {
  text-decoration: underline;
}

svg#freepik_stories-emails:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-emails.animated #freepik--mail-box--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp,
    1.5s Infinite linear floating;
  animation-delay: 0s, 1s;
}
svg#freepik_stories-emails.animated #freepik--Envelopes--inject-2 {
  animation: 1.5s Infinite linear heartbeat;
  animation-delay: 0s;
}
svg#freepik_stories-emails.animated #freepik--open-envelope--inject-2 {
  animation: 1.5s Infinite linear floating;
  animation-delay: 0s;
}
svg#freepik_stories-emails.animated #freepik--paper-plane--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lightSpeedRight {
  from {
    transform: translate3d(50%, 0, 0) skewX(-20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.css-65fob2 > *:not(style) ~ *:not(style) {
  margin-top: 0 !important;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  margin-bottom: 0px;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
}
